import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import _, { get, findIndex } from "lodash";
import Layout from "src/components/layout/main";
import SEO from "src/components/seo";
import Container, { ContainerCenter } from 'src/components/base/container';
import Section from 'src/components/base/section';
import Flex from 'src/components/base/flex';
import Icon from 'src/components/base/icon';
import LinkExternal from 'src/components/base/linkExternal';
import Typography from 'src/components/base/typography';
import Button, { ButtonBase } from 'src/components/base/button';
import NewsletterForm from 'src/components/blog/newsletterForm';
import brentprofile from "src/images/home/brentprofile.jpg";
import markprofile from "src/images/home/markprofile.jpg";
import CaretRight from 'src/images/icons/caret_right.svg';
import EmailIcon from "src/images/icons/mailwhite.svg";
import GithubIcon from "src/images/icons/githubwhite.svg";
import LinkedinIcon from "src/images/icons/linkedinwhite.svg";
import ExternalIcon from "src/images/icons/external.svg";

const iconFor = (social) => {
  switch (social) {
    case 'linkedin': return <LinkedinIcon />;
    case 'github': return <GithubIcon />;
    case 'email': return <EmailIcon />;
    default: return <ExternalIcon />;
  }
}

const TopNavButton = ({ reverse, children, article }) => {

  const Root = article ? Link : 'a';

  const iconStyle = {
    minWidth: '1rem',
    minHeight: '1rem',
    ...(reverse ? {
      transform: 'rotate(180deg)',
      marginRight: '1rem',
    } : {
      marginLeft: '1rem',
    })
  }

  const buttonStyle = reverse ? { paddingLeft: 0 } : { paddingRight: 0 };

  const articleTitlePruneLength = 34;

  return (
    <Root
      to={article ? "/blog/" + article.node.slug : "#"}
      data-hover="none"
      style={{
        maxWidth: 180,
      }}
      disabled={!article}
    >
      <ButtonBase color="silver" block disabled={!article} style={buttonStyle}>
        <Flex.box direction={`row${reverse ? "-reverse" : ""}`} align="center">
          <Flex.item fluid>
            <Typography block weight="bold" align={reverse ? "left" : "right"} style={{
              textTransform: "uppercase",
              fontSize: "0.6rem",
              marginBottom: ".2rem",
            }}>
              {children}
            </Typography>
            {article && (
              <Typography size="6" color="gray" block align={reverse ? "left" : "right"}>
                {article.node.title.length > articleTitlePruneLength
                  ? article.node.title.slice(0, articleTitlePruneLength + 1) + "..."
                  : article.node.title
                }
              </Typography>
            )}
          </Flex.item>
          <Icon size="1.5rem" style={iconStyle}>
            <CaretRight />
          </Icon>
        </Flex.box>
      </ButtonBase>
    </Root>
  )
}

const Article = (props) => {

  const { data } = props;

  const article = data.contentfulBlog;
  const all = data.allContentfulBlog.edges;
  const index = findIndex(all, a => a.node.id === article.id);
  const prev = all[index - 1];
  const next = all[index + 1];

  let image = "", profile;
  if (article.seoAuthor.includes("Brent")) {
    image = brentprofile;
    profile = data.profiles.nodes.find(p => p.frontmatter.name.includes("Brent"));
  };
  if (article.seoAuthor.includes("Mark")) {
    image = markprofile
    profile = data.profiles.nodes.find(p => p.frontmatter.name.includes("Mark"));
  };

  const socialLinks = profile ? _.entries(_.pick(profile.frontmatter, ['linkedin', 'github', 'email'])) : null;

  return (
    <Layout >
      <SEO title={article.title} description={article.shortDescription} />
      <Container as="article" padding>

        {/* Top Nav */}
        <Section y="1rem">
          <Flex.box direction="row" justify="space-between" align="center">
            <TopNavButton article={prev} reverse>Previous</TopNavButton>
            <TopNavButton article={next}>Next</TopNavButton>
          </Flex.box>
        </Section>

        {/* title */}
        <Section top="1rem" bottom="2rem">
          <Typography as="h1" align="left" size="1" family="display" weight="bold">
            {article.title}
          </Typography>
        </Section>

        {/* author info */}
        <Section block>
          <Flex.box>
            <Section right="1rem">
              <Icon bgImage={image} circle size="3rem" />
            </Section>
            <Flex.item fluid>
              <Flex.box direction="column" justify="space-around" align="flex-start">
                <Typography as="span" size="5" family="display" weight="medium">{article.seoAuthor}</Typography>
                <Typography size="6">{article.createdAt}</Typography>
              </Flex.box>
            </Flex.item>
          </Flex.box>
        </Section>

        {/* Figure */}
        <Section as="figure" top="2rem" block>
          <Section bottom="1rem">
            <Icon height="280px" margin="auto">
              <img src={get(article, "featuredImage.file.url")} alt="featured" />
            </Icon>
          </Section>
          <Typography as="figcaption" size="6" weight="medium" color="gray" align="center" block>
            {get(article, "featuredImage.title")}
          </Typography>
        </Section>

        {/* Body */}
        <Section
          block
          top="1rem"
          dangerouslySetInnerHTML={{
            __html: get(article, "content.childMarkdownRemark.html"),
          }}
        />

      </Container>

      {/* Page bottom */}
      <Section top="8rem">
        <ContainerCenter>

          {/* Social Links */}
          {socialLinks && (
            <Section bottom="5rem" x="3rem">
              <Flex.box direction="row" justify="space-around" align="center">
                {socialLinks.map(([social, href], i) => (
                  <LinkExternal href={social === 'email' ? `mailto:${href}` : href} data-color="none" data-hover="color" title={href}>
                    <Icon size="2rem">{iconFor(social)}</Icon>
                  </LinkExternal>
                ))}
              </Flex.box>
            </Section>
          )}

          {/* Pagination */}
          <Flex.box direction="column" align="center">

            {/* Buttons */}
            <Flex.box justify="space-between" style={{ marginBottom: '1rem' }}>
              <Flex.item basis="calc(50% - .5rem)">
                <Link to={prev ? `/blog/${prev.node.slug}` : ''} disabled={!prev} data-hover="noline">
                  <Button outline block disabled={!prev}>
                    Previous
                  </Button>
                </Link>
              </Flex.item>
              <Flex.item basis="calc(50% - .5rem)">
                <Link to={next ? `/blog/${next.node.slug}` : ''} disabled={!next} data-hover="noline">
                  <Button outline block disabled={!next}>
                    Next
                  </Button>
                </Link>
              </Flex.item>
            </Flex.box>

            <Link to="/blog" style={{ width: '100%' }} data-hover="noline">
              <Button block>
                Return to Blog Home
              </Button>
            </Link>
          </Flex.box>
        </ContainerCenter>
      </Section>

      {/* Newsletter form */}
      <Section y="3rem">
        <NewsletterForm dark />
      </Section>


    </Layout>
  );
}

Article.propTypes = {
  article: PropTypes.object,
}

export default Article;

export const query = graphql`
  query ArticleQuery($slug: String!) {
    contentfulBlog(slug: { eq: $slug }) {
      id
      title
      shortDescription
      featuredImage {
        file {
          url
        }
        title
      }
      seoAuthor
      createdAt(formatString: "MMMM Do, YYYY")
      content {
        childMarkdownRemark {
          id
          html
        }
      }
    }
    allContentfulBlog(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          id
          title
          slug
          createdAt
        }
      }
    }
    profiles: allMarkdownRemark(
            filter: { fields: { sourceName: { eq: "people" } } },
            sort: { order: ASC, fields: frontmatter___order }
        ) {
            nodes {
                id
                excerpt (pruneLength: 500, format: HTML)
                frontmatter {
                    order
                    name
                    suffix
                    imagePath
                    email
                    github
                    linkedin
                    resume
                }
                fields {
                    sourceName
                }
            }
        }
  }
`
